import axios from 'axios';

// if (process.env.NODE_ENV === 'development') {
//   // ...
//   axios.defaults.baseURL = "/api"
// } else if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = "http://skattejaktinaturen.idi.ntnu.no:3000/api";
// }
axios.defaults.baseURL = "/api"


export type Loype = {
  loype_id: number,
  loype_navn: string,
  center_lat: number,
  center_long: number
}

class LoypeService {
  //Henter alle løyper
  getAll() {
    return axios.get<Loype[]>('/loype').then((response) => response.data)
  }

  //Henter spesifikk løype
  get(loype_id: number) {
    return axios.get<Loype>('/loype/' + loype_id).then((response) => response.data)
  }

  //Oppretter ny løype
  create(loype_navn: string, center_lat: number, center_long: number) {
    return axios.post<{ loype_id: number; }>('/loype', {
      loype_navn: loype_navn,
      center_lat: center_lat,
      center_long: center_long
    })
      .then((response) => response.data)
  }

  //Oppdaterer løype
  update(loype: Loype) {
    return axios.put('/loype', loype).then((response) => response.data)
  }

  //Sletter løype
  delete(loype_id: number) {
    return axios.delete<Loype>('/loype/' + loype_id).then((response) => response.data)
  }
}

export let loypeService = new LoypeService();