import React from "react";
import { Card, Container } from "react-bootstrap";
import logo1 from "../Bilder/logo.png";
import Button from 'react-bootstrap/Button';


function OmOss() {


  //om oss og forside med informasjon om webappliaksjonen og Gjærevollsenteret
  return (
    <>
      <div className='container-fluid' style={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', marginTop: '30px' }}>
        <Container style={{
          justifyContent: 'center',
          alignItems: 'center',

          display: 'flex'
        }}>
          <img src={logo1} style={{
            width: "80vw",
            maxWidth: "250px"
          }} />
        </Container>

        <Container style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
          <Card style={{ backgroundColor: 'rgb(0, 105, 105)', width: '90vw', }} >
            <Card.Body>
              <div className="omoss">
                <Card.Title style={{ color: "white", fontSize: '30px', textAlign: 'center', justifyContent: 'center' }}>
                  Om oss
                </Card.Title>
                <p style={{ color: "white", textAlign: 'center', justifyContent: 'center' }}>
                  Gjærevollsenteret er et forskningssenter ved NTNU som jobber med naturmangfold og bærekraft. Senteret er bygget på tre hovedpilarer: forskning, utdanning og formidling. Vi er et senter for framtidsanalyser av naturmangfold. Det vil si at vi jobber for å utvikle analyser og verktøy som kan hjelpe oss å forstå hvordan vi påvirker naturen. Dermed blir vi i stand til å ta bedre valg både for samfunnet og naturmangfoldet. Å øke kunnskapen til folk om naturmangfold, hvordan naturen henger sammen og hvordan vi best tar vare på den er et viktig mål for oss. Vi håper at utforskerløypa kan bidra til dette.
                </p>
              </div>
            </Card.Body>
          </Card>


          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button href="https://www.ntnu.no/gjaerevoll" style={{
              marginTop: '20px',
              background: 'rgb(0, 105, 105)',
              marginBottom: '20px',
              color: "white"
            }}>Les mer her</Button>
          </div>
        </Container>

      </div>
    </>
  );
}

export default OmOss;