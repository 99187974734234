import axios from 'axios';

// if (process.env.NODE_ENV === 'development') {
//   // ...
//   axios.defaults.baseURL = "/api"
// } else if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = "http://skattejaktinaturen.idi.ntnu.no:3000/api";
// }
axios.defaults.baseURL = "/api"



export type Svar = {
  svar_id: number,
  svar_tekst: string,
  resultat: boolean | number,
  sporsmaal_id: number
}

class SvarService {

  //Henter svaralternativer for gitt spørsmål for gitt post i gitt løype
  getSporsmaalSvar(loype_id: number, post_id: number, sporsmaal_id: number) {
    return axios.get<Svar[]>('/loype/' + loype_id + '/post/' + post_id + '/sporsmaal/' + sporsmaal_id + '/svar').then((response) => response.data)
  }

  //Henter gitt svaralternativ for gitt spørsmål for gitt post i gitt løype
  getSvar(loype_id: number, post_id: number, sporsmaal_id: number, svar_id: number) {
    return axios.get<Svar[]>('/loype/' + loype_id + '/post/' + post_id + '/sporsmaal/' + sporsmaal_id + '/svar/' + svar_id).then((response) => response.data)
  }

  //Oppretter nytt svaralternativ
  create(
    svar_tekst: string,
    resultat: boolean | number,
    sporsmaal_id: number
  ) {
    return axios.post<{ svar_id: number }>('/svar', {
      svar_tekst: svar_tekst,
      resultat: resultat,
      sporsmaal_id: sporsmaal_id
    })
      .then((response) => response.data.svar_id)
  }

  //Oppdaterer svaralternativ
  update(svar: Svar) {
    return axios.put('/svar', svar).then((response) => response.data)
  }

  //Sletter svaralternativ
  delete(id: number) {
    console.log(`frontend delete med id: ${id}`)
    return axios.delete<Svar>('/svar/' + id).then((response) => response.data)
  }
}

export let svarService = new SvarService();