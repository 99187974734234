import { useContext, useEffect, useState } from "react";
import { Respons, responsService } from "../Services/respons-service";
import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import SletteModal from "./sletteModal";
// import { AuthContext } from "../context/AuthContext";


export function QuestionResponse() {
  const [responsSomSkalSlettes, setResponsSomSkalSlettes] = useState<Respons | null>(null);
  const [questionId, setQuestionId] = useState<number>(0)
  const [routeId, setRouteId] = useState<number>(0)
  const [postId, setPostId] = useState<number>(0)
  const [response, setResponse] = useState<Respons | null>()
  // const user = useContext(AuthContext)
  const [responseText, setResponseText] = useState<string | null>()

  const user = false


  //setter respons som skal slettes
  const handleSlettKlikkRespons = (response: Respons) => {
    setResponsSomSkalSlettes(response)
  }



  const handleBekreftSlettRespons = (response: Respons | null) => {

    if (response != null) {
      //sletter respons
      responsService.delete(response.respons_id).catch((error) => alert(error.message))
    }
    setResponsSomSkalSlettes(null)
    location.reload()

  }
  //setter respons som skal slettes til null
  const handleIkkeSlettRespons = () => {
    setResponsSomSkalSlettes(null)
  }

  useEffect(() => {

    //bruker url til å sette spørsmålsit, postid og ruteid
    const url = window.location.href.split('/').slice(1);
    const slug = url[url.length - 1]
    const slug2 = url[url.length - 2]
    const slug3 = url[url.length - 3]
    setQuestionId(parseInt(slug))
    setPostId(parseInt(slug2))
    setRouteId(parseInt(slug3))
    if (questionId !== 0 && routeId !== 0 && postId !== 0) {
      findResponse()
    }


  }, [questionId])

  const findResponse = () => {
    //henter respons til gitt spørsmål
    responsService.getRespons(routeId, postId, questionId).then(function (response: any) {
      return setResponse(response)
    })

  }

  const addResponse = () => {

    if (responseText) {
      //lager respons til spørsmål
      responsService.create(responseText, questionId).then(function (response) {
        return response
      })
      location.reload()
    }
    else {
      alert("Du må skrive inn en respons for å opprette")
    }
  }

  if (user && response) return (
    /* Card som viser frem respons for spørsmål */

    <><Card style={{ display: "flex", border: "none", alignItems: "center" }}> <Card.Body style={{ padding: "10px" }}>
      <Card style={{ backgroundColor: 'rgb(230, 240, 241)', maxWidth: " 500px", width: "80vw", justifyContent: 'center', alignItems: "center", border: 'none', background: " rgb(0, 105, 105)", margin: "10px", padding: "10px" }}>
        <h3>Respons</h3>
        <ReactMarkdown>{response.respons_tekst}</ReactMarkdown>

        <>
          {/* Knapp for å slette respons */}
          <Button className='slettKnapp' style={{ margin: '2vh', height: '40px', backgroundColor: "#F25749" }} onClick={() => handleSlettKlikkRespons(response)}>
            Slett respons
          </Button>
          {/* Modal som vises når slett respons knapp blir klikket på */}
          <SletteModal
            visModal={responsSomSkalSlettes !== null}
            elementSomSkalSlettes={"respons"}
            onCancel={handleIkkeSlettRespons}
            onConfirm={() => handleBekreftSlettRespons(responsSomSkalSlettes)} />
        </>
      </Card>
    </Card.Body></Card></>

  )

  if (user && !response) return (

    <><Card style={{ display: "flex", border: "none", alignItems: "center" }}> <Card.Body style={{ padding: "10px" }}>
      <Card style={{ backgroundColor: 'rgb(230, 240, 241)', maxWidth: " 500px", width: "80vw", justifyContent: 'center', alignItems: "center", border: 'none', background: " rgb(0, 105, 105)", margin: "10px", padding: "10px" }}>
        <h3>Respons</h3>
        <p>Det finnes ingen respons for dette spørsmålet, husk at alle spørsmål MÅ ha en respons!</p>
      </Card>
    </Card.Body></Card><Card style={{ display: "flex", border: "none", alignItems: "center" }}> <Card.Body style={{ padding: "10px" }}>
      <Card style={{ backgroundColor: 'rgb(230, 240, 241)', maxWidth: " 500px", width: "80vw", justifyContent: 'center', alignItems: "center", border: 'none', background: " rgb(0, 105, 105)", margin: "10px", padding: "10px" }}>
        <h3>Legg til respons</h3>
        {/* Form for å legge til ny respons dersom respons ikke eksisterer */}
        <Form.Control
          type="text"
          as="textarea"
          rows={3}
          onChange={(e) => setResponseText(e.currentTarget.value)}
          placeholder="Respons på spørsmål" />
        <Button className="lightButton" onClick={() => { addResponse() }}>Legg til</Button>
      </Card>
    </Card.Body></Card></>

  )
  else return (
    /* Dette vises om brukeren ikke er innlogget */
    <Card style={{ display: "flex", border: "none", alignItems: "center" }}>
      Du må være logget inn for å se denne siden
    </Card>
  )

}

