import * as React from 'react';
import { BrowserRouter } from 'react-router-dom'
// @ts-ignore:next-line
import * as ReactDOMClient from 'react-dom/client';
import { App } from './Components/app';

// @ts-ignore:next-line
const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(<BrowserRouter>
  <App />
</BrowserRouter>);
