import React from "react";
import { Modal, Button, ModalHeader, ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap'

interface ModalProps {
  visModal: boolean;
  elementSomSkalSlettes: string | undefined | null;
  onConfirm: () => void;
  onCancel: () => void;
}
//Modal som kan gjenbrukes når elementer skal slettes for å sikre at brukeren faktisk ønsker å slette
const SletteModal: React.FC<ModalProps> = ({ visModal, elementSomSkalSlettes, onConfirm, onCancel }) => {
  return (
    <Modal show={visModal} style={{ backgroundColor: "rgb(230, 240, 241)" }}>
      <ModalHeader style={{ backgroundColor: "rgb(0, 105, 105)", border: 'none' }}>
        <ModalTitle>Er du sikker?</ModalTitle>
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "rgb(0, 105, 105)", border: 'none' }}>
        Er du helt sikker på at du vil slette {elementSomSkalSlettes}?
      </ModalBody>
      <ModalFooter style={{ backgroundColor: "rgb(0, 105, 105)", border: 'none' }}>
        <Button className="ikkeSlettKnapp" onClick={onCancel}>
          Nei, ikke slett {elementSomSkalSlettes}
        </Button>
        <Button className="slettKnapp" onClick={onConfirm}>
          Ja, slett {elementSomSkalSlettes}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SletteModal;