import axios from 'axios';

// if (process.env.NODE_ENV === 'development') {
//   // ...
//   axios.defaults.baseURL = "/api"
// } else if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = "http://skattejaktinaturen.idi.ntnu.no:3000/api";
// }
axios.defaults.baseURL = "/api"

export type Respons = {
  respons_id: number,
  respons_tekst: string,
  sporsmaal_id: number
}

class ResponsService {

  //Henter respons for gitt spørsmål i gitt løype
  getRespons(loype_id: number, post_id: number, sporsmaal_id: number) {
    return axios.get<Respons[]>('/loype/' + loype_id + '/post/' + post_id + '/sporsmaal/' + sporsmaal_id + '/respons').then((response) => response.data)
  }

  //Oppretter ny respons
  create(
    respons_tekst: string,
    sporsmaal_id: number
  ) {
    return axios.post<{ respons_id: number }>('/respons', {
      respons_tekst: respons_tekst,
      sporsmaal_id: sporsmaal_id
    })
      .then((response) => response.data)
  }


  //Oppdaterer respons
  update(respons: Respons) {
    return axios.put('/respons', respons).then((response) => response.data)
  }

  //Sletter respons
  delete(respons_id: number) {
    return axios.delete<Respons>('/respons/' + respons_id).then((response) => response.data)
  }
}

export let responsService = new ResponsService();