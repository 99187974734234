import * as React from 'react';
import { Switch, Route } from 'react-router-dom'
import NavigationBar from './navbar';
import FAQ from './FAQ';
import OmOss from './omoss';
import Question from './question';
import Map from './map';
// import { AuthProvider } from '../provider/AuthProvider';
// import Login from './login';
import Loyper from './loyper';
// import NewRoutes from './newroute';

import { PostQuestions } from './postquestions';
import { QuestionAlternatives } from './questionalts';
import { QuestionResponse } from './questionresponse';
export function App() {

  return (
    <React.StrictMode>
      {/* <AuthProvider> */}
      <NavigationBar />
      <Switch>
        <Route exact path="/" component={OmOss} />
        {/* <Route exact path="/newQuestion/:loype_id(\d+)/:post_id(\d+)" component={newQuestion} /> */}
        <Route path="/FAQ" component={FAQ} />
        <Route path="/loype/:loype_id(\d+)/post/:post_id(\d+)" component={Question} />
        <Route path="/p:short_url" component={Question} />

        <Route path="/loyper" component={Loyper} />
        <Route path="/kart/:id(\d+)" component={Map} />
        {/* <Route path="/login" component={Login} /> */}
        {/* <Route path="/nyloype" component={NewRoutes} />
        <Route path="/endreloype/:loype_id(\d+)" component={EditRoutes} /> */}
        <Route path="/postquestions/:loype_id(\d+)/:post_id(\d+)" component={PostQuestions} />
        <Route path="/questionalternatives/:loype_id(\d+)/:post_id(\d+)/:sporsmaal_id(\d+)" component={QuestionAlternatives} />
        <Route path="/questionresponse/:loype_id(\d+)/:post_id(\d+)/:sporsmaal_id(\d+)" component={QuestionResponse} />
      </Switch>
      {/* </AuthProvider> */}
    </React.StrictMode>
  );
}
