import React, { useContext, useEffect, useState } from "react";
import { Sporsmaal, sporsmaalService } from "../Services/sporsmaal-service";
import { Button, Card, Nav } from "react-bootstrap";
import { Post, postService } from "../Services/post-service";
import ReactMarkdown from "react-markdown";
import SletteModal from "./sletteModal";
import { Link } from "react-router-dom";

export function PostQuestions() {
  const [routeId, setRouteId] = useState<number>(0)
  const [postId, setpostId] = useState<number>(0)
  const [post, setPost] = useState<Post>()
  const [loaded, setLoaded] = useState<Boolean>(false)
  const [questions, setQuestions] = useState<Sporsmaal[] | null>()
  const [sporsmaalSomSkalSlettes, setSporsmaalSomSkalSlettes] = useState<Sporsmaal | null>(null);


  useEffect(() => {

    if (routeId && postId && loaded) {
      //henter post
      postService.getPost(postId, routeId).then(function (response) {
        return setPost(response)
      })
      //henter postens spørsmål
      sporsmaalService.getPostSporsmaal(routeId, postId).then(function (response) {
        return setQuestions(response)
      })

    }


  }, [routeId, postId])





  useEffect(() => {

    //Bruker url til å sette ruteid og posstid
    const url = window.location.href.split('/').slice(1);
    const post = url[url.length - 1]
    const route = url[url.length - 2]

    setRouteId(parseInt(route))
    setpostId(parseInt(post))
    setLoaded(true)


  }, [])


  //setter spørsmål som skal slettes
  const handleSlettKlikkSporsmaal = (sporsmaal: Sporsmaal) => {
    setSporsmaalSomSkalSlettes(sporsmaal)
  }

  const handleBekreftSlettSporsmaal = (sporsmaal: Sporsmaal | null) => {
    if (sporsmaal != null) {
      //sletter spørsmål med gitt id
      sporsmaalService.delete(sporsmaal.sporsmaal_id).catch((error) => alert(error.message
        + "\n\nAlle responser og svaralternativer som tilhører spørsmålet må slettes før spørsmålet kan slettes"))
    }
    setSporsmaalSomSkalSlettes(null)
    location.reload()
  }

  //setter spørsmål som skal slettes til null
  const handleIkkeSlettSporsmaal = () => {
    setSporsmaalSomSkalSlettes(null)
  }


  //viser spørsmålene som hører til posten
  if (false) return (
    <Card style={{ display: "flex", border: "none", alignItems: "center" }}><h1>Spørsmål for {post?.post_navn}</h1>
      {questions && questions.map((question, index) =>
        < Card className="question" style={{ maxWidth: "500px", width: "80vw", justifyContent: 'center', alignItems: "center", border: 'none', backgroundColor: " rgb(0, 105, 105)", margin: "10px" }}>
          <h4>Spørsmål nr {index + 1}</h4>
          <Card.Img className='questionImages' variant="top" src={question?.bilde} />
          <p style={{ color: 'black' }}>Foto: {question?.foto_kreditt}</p>
          <h5 style={{ margin: "10px", padding: "10px", fontSize: "20px", fontWeight: "bold" }}><ReactMarkdown>{question?.sporsmaalstekst}</ReactMarkdown></h5>
          {/* Knapp for å se spørsmålets alternativer */}
          <Button style={{ margin: "10px" }}> <Nav.Link as={Link} to={'/questionalternatives/' + routeId + '/' + postId + '/' + question.sporsmaal_id}>
            Se alternativer
          </Nav.Link></Button>
          <>{/* Knapp for å se spørsmålets respons */}
            <Button style={{ margin: "10px" }} ><Nav.Link as={Link} to={'/questionresponse/' + routeId + '/' + postId + '/' + question.sporsmaal_id}>
              Se respons
            </Nav.Link></Button>
            {/* Knapp for å slette spørsmål */}
            <Button className='slettKnapp' style={{ margin: '2vh', height: '40px', backgroundColor: "#F25749" }} onClick={() => handleSlettKlikkSporsmaal(question)}>
              Slett spørsmål
            </Button>

            {/* Modal som vises om man klikker på slett spørsmål knappen */}
            <SletteModal
              visModal={sporsmaalSomSkalSlettes !== null}
              elementSomSkalSlettes={'spørsmål'}
              onCancel={handleIkkeSlettSporsmaal}
              onConfirm={() => handleBekreftSlettSporsmaal(sporsmaalSomSkalSlettes)} />
          </>
        </Card>
      )
      }
      {questions?.length == 0 && < p > Det finnes ingen spørsmål for denne posten</p>}

      {/* Knapp som leder til siden for å vise spørsmål */}
      < Button style={{ margin: '2vh', height: "40px", }}>
        <Nav.Link as={Link} to={'/newQuestion/' + routeId + '/' + postId}>
          Legg til spørsmål
        </Nav.Link>
      </Button>
    </Card>

  )
  else return (
    /* Dette vises om brukeren ikke er innlogget */
    <Card style={{ display: "flex", border: "none", alignItems: "center" }}>
      Du må være logget inn for å se denne siden
    </Card>
  )
}



