import axios from 'axios';


// if (process.env.NODE_ENV === 'development') {
//   // ...
//   axios.defaults.baseURL = "/api"
// } else if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = "http://skattejaktinaturen.idi.ntnu.no:3000/api";
// }
axios.defaults.baseURL = "/api"


export type Post = {
  post_id: number,
  post_navn: string,
  loype_id: number,
  longitude: number,
  latitude: number,
}

class PostService {
  //Henter alle poster
  getAll() {
    return axios.get<Post[]>('/post').then((response) => response.data)
  }

  //Henter alle poster i gitt løype
  getLoypePoster(loype_id: number) {
    return axios.get<Post[]>('/loype/' + loype_id + '/post').then((response) => response.data)
  }

  //Henter spesifikk post i gitt løype
  getPost(post_id: number, loype_id: number) {
    return axios.get<Post>('/loype/' + loype_id + '/post/' + post_id).then((response) => response.data)
  }

  //Oppretter ny post
  create(
    post_navn: string,
    loype_id: number,
    longitude: number,
    latitude: number
  ) {
    return axios.post<{ post_id: number }>('/post', {
      post_navn: post_navn,
      loype_id: loype_id,
      longitude: longitude,
      latitude: latitude
    })
      .then((response) => response.data.post_id)
  }

  //Oppdaterer post
  update(post: Post) {
    return axios.put('/post', post).then((response) => response.data)
  }

  //Sletter post
  delete(post_id: number) {
    return axios.delete<Post>('/post/' + post_id).then((response) => response.data)
  }
}

export let postService = new PostService();