import React, { useEffect, useState } from 'react';
import { ModalProps, Nav } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { sporsmaalService } from '../Services/sporsmaal-service';
import { Sporsmaal } from '../Services/sporsmaal-service';
import { Svar, svarService } from '../Services/svar-service';
import { Omit, BsPrefixProps } from 'react-bootstrap/esm/helpers';
import { Respons, responsService } from '../Services/respons-service';
import { Post, postService } from '../Services/post-service';
import { Link, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import short_urls from '../short_urls.json';

function Question() {
  const short_url = useParams<{ short_url: string }>();

  let post_id = 0;
  let loype_id = 0;

  if(!!short_url) {
    post_id = short_urls[short_url.short_url]["post"]
    loype_id = short_urls[short_url.short_url]["loype"]
  }


  //Setter konstanter
  const [modalShow, setModalShow] = React.useState(false);
  const [answer, setAnswer] = useState<Svar[]>([{ svar_id: 0, svar_tekst: "", resultat: false, sporsmaal_id: 0 }]);
  const [questionIndex, setQuestionIndex] = React.useState(0)
  const [question, setQuestion] = useState<Sporsmaal>({ sporsmaal_id: 0, bilde: "", foto_kreditt: "", sporsmaalstekst: "", post_id: 0 })
  const [finishedPost, setFinishedPost] = React.useState(0)
  const [isCorrect, setIsCorrect] = useState<any>(false);
  const [allQuestions, setAllQuestions] = useState<Sporsmaal[]>([{ sporsmaal_id: 0, bilde: "", foto_kreditt: "", sporsmaalstekst: "", post_id: 0 }])
  const [questionResponse, setQuestionResponse] = useState<Respons[]>([{ respons_id: 0, respons_tekst: "", sporsmaal_id: 0 }])
  const [post, setPost] = useState<Post>({ post_id: 0, post_navn: "", loype_id: 0, longitude: 0, latitude: 0 })

  let rightAnswer;
  answer.filter((a) => (a.resultat === true) ? rightAnswer = a.svar_tekst.toLowerCase() : false)

  // Hent fullførte poster fra localStorage hvis det finnes
  const lagredePoster = localStorage.getItem('fullførtePoster');
  let fullførtePoster: number[] = lagredePoster ? JSON.parse(lagredePoster) : [];

  // Legg til post_id i fullførtePoster når posten er fullført
  function fullførPost(post: Post) {
    fullførtePoster.push(post.post_id);
    localStorage.setItem('fullførtePoster', JSON.stringify(fullførtePoster));
  }

  const [trigger, setTrigger] = useState(false);
  //Funksjon som håndterer når bruker klikker på svaralternativer
  const handleClick = () => {
    setQuestionIndex(questionIndex + 1)
    responsService.getRespons(loype_id, post_id, question.sporsmaal_id).then(function (response) {
      return setQuestionResponse(response)
    })
    if (questionIndex == allQuestions.length - 1) {
      setFinishedPost(1)
      fullførPost(post)
    }
  }


  useEffect(() => {
    sporsmaalService.getPostSporsmaal(loype_id, post_id).then(function (response) {
      return setAllQuestions(response)
    })
    setTrigger(prev => !prev)
  }, [])
  useEffect(() => {
    sporsmaalService.getPostSporsmaal(loype_id, post_id).then(function (response) {
      return setQuestion(response[questionIndex])
    })
  }, [trigger])


  useEffect(() => {
    svarService.getSporsmaalSvar(loype_id, post_id, question.sporsmaal_id).then(function (response: Svar[]) {
      return setAnswer(response)
    })
  }, [question])



  useEffect(() => {
    postService.getPost(post_id, loype_id).then(function (response) {
      setPost(response)

    })
  }, [])

  //Returnerer spørsmålskomponent
  return (
    <><Card className="d-lg-none d-xl-none d-md-block d-xs-block d-sm-block" style={{ width: '100vw', height: '100vh', border: "none" }}>

      <Card.Body>
        < Card className="question" style={{ alignItems: "center", border: 'none', backgroundColor: " rgb(0, 105, 105)" }}>
          <h5 style={{ fontWeight: "bold", padding: "5px" }}>Post {post?.post_navn}</h5>
          <Card.Img className='questionImages' variant="top" src={question?.bilde} />
          <p style={{ color: 'black' }}>Foto: {question?.foto_kreditt}</p>
          <h5 style={{ margin: "10px", padding: "10px", fontSize: "20px", fontWeight: "bold" }}><ReactMarkdown>{question?.sporsmaalstekst}</ReactMarkdown></h5>
        </Card>
        <Card style={{ border: "none", display: "flex", justifyContent: "center" }}>
          {!modalShow && answer.map((s) => <Button key={s.svar_id} onClick={() => { setModalShow(true); handleClick(), setIsCorrect(s.resultat) }}
            style={{ fontSize: "20px", minHeight: "10vh", margin: '10px', backgroundColor: '#CFFF8D', color: '#425F57', borderColor: '#425F57' }}
            variant="primary"><ReactMarkdown>{s.svar_tekst}</ReactMarkdown></Button>
          )}


          {modalShow && <MyVerticallyCenteredModal
            show={modalShow}
            iscorrect={isCorrect}
            postfinished={finishedPost}
            question={question}
            response={questionResponse}
            rightanswer={rightAnswer}
            onClick={() => {
              setQuestion(allQuestions[questionIndex])
              setModalShow(false)
            }}

          />
          }

        </Card>
        <>


        </>
      </Card.Body>

    </Card><>

      </></>
  );
}



//modal som viser respons når svaralterativ er klikket på
// leder til neste spørsmål eller kart for løypen dersom det ikke er flere spørsmål
function MyVerticallyCenteredModal(props: JSX.IntrinsicAttributes &
  Omit<Pick<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "key"
    | keyof React.HTMLAttributes<HTMLDivElement>> & {
      ref?: ((instance: HTMLDivElement | null) => void)
      | React.RefObject<HTMLDivElement> | null | undefined;
    }, BsPrefixProps<"div"> & ModalProps>
  & BsPrefixProps<"div"> & ModalProps & { children?: React.ReactNode; }) {

    const short_url = useParams<{ short_url: string }>();

    let post_id = 0;
    let loype_id = 0;
  
    if(!!short_url) {
      post_id = short_urls[short_url.short_url]["post"]
      loype_id = short_urls[short_url.short_url]["loype"]
    }

  let responsTekstRiktig = `Bra jobba! Det stemmer at korrekt svar er ${props.rightanswer}. Visste du at:`
  let responsTekstFeil = `Det er desverre feil. Korrekt svar er ${props.rightanswer}. Visste du at:`

  return (
    <Card
      {...props}
      style={{ backgroundColor: "rgb(0, 105, 105)", color: "white" }}
      className="respons"
      onClick={undefined}
    >
      {props.iscorrect == 1 ? <Card.Body className="respons" style={{ backgroundColor: "#B5D99D", fontSize: "20px", border: "none" }}  >
        <p style={{ fontWeight: "bold" }}><ReactMarkdown>{responsTekstRiktig}</ReactMarkdown></p>
        <p><ReactMarkdown>{props.response.respons_tekst}</ReactMarkdown></p>

      </Card.Body>
        :
        <Card.Body style={{
          backgroundColor:
            "rgb(0, 105, 105)", fontSize: "20px", borderRadius: "10px"
        }}  >

          <p style={{
            fontWeight: "bold", backgroundColor:
              "#F25749", borderRadius: "10px", paddingLeft: "10px"
          }}><ReactMarkdown>{responsTekstFeil}</ReactMarkdown></p>
          <p><ReactMarkdown>{props.response.respons_tekst}</ReactMarkdown></p>
        </Card.Body>}
      <Card.Footer style={{ backgroundColor: "rgb(0, 105, 105)", border: "none", display: "flex", justifyContent: "center", minWidth: "150px", margin: "10px" }} >
        {!props.postfinished ? <Button onClick={() => { props.onClick() }}
          className='nextButton' >
          Neste spørsmål </Button>
          : <Button className='nextButton'>
            <Nav.Link as={Link} to={'/kart/' + loype_id} >
              Du er ferdig med denne posten! Se på kartet for å finne flere poster
            </Nav.Link></Button>}
      </Card.Footer>
    </Card>
  );
}

export default Question;