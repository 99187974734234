import axios from 'axios';

// if (process.env.NODE_ENV === 'development') {
//   // ...
//   axios.defaults.baseURL = "/api"
// } else if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = "http://skattejaktinaturen.idi.ntnu.no:3000/api";
// }
axios.defaults.baseURL = "/api"


export type Sporsmaal = {
  sporsmaal_id: number,
  bilde: string,
  foto_kreditt: string,
  sporsmaalstekst: string,
  post_id: number
}

class SporsmaalService {
  //Henter alle spørsmål
  getAll() {
    return axios.get<Sporsmaal[]>('/sporsmaal').then((response) => response.data)
  }

  //Henter et spesifikt spørsmål for gitt post i gitt løype
  getPostSporsmaal(loype_id: number, post_id: number) {
    return axios.get<Sporsmaal[]>('/loype/' + loype_id + '/post/' + post_id + '/sporsmaal').then((response) => response.data)
  }

  //Henter et spesifikt spørsmål
  getSporsmaal(loype_id: number, post_id: number, sporsmaal_id: number) {
    return axios.get<Sporsmaal[]>('/loype/' + loype_id + '/post/' + post_id + '/sporsmaal/' + sporsmaal_id).then((response) => response.data)
  }

  //Oppretter nytt spørsmål
  create(
    sporsmaalstekst: string,
    bilde: string,
    fotoKred: string,
    post_id: number
  ) {
    return axios.post<{ sporsmaal_id: number }>('/sporsmaal', {
      sporsmaalstekst: sporsmaalstekst,
      bilde: bilde,
      fotoKred: fotoKred,
      post_id: post_id
    })
      .then((response) => response)
  }

  //Oppdaterer spørsmål
  update(sporsmaal: Sporsmaal) {
    return axios.put('/sporsmaal', sporsmaal).then((response) => response.data)
  }

  //Sletter spørsmål
  delete(sporsmaal_id: number) {
    return axios.delete<Sporsmaal>('/sporsmaal/' + sporsmaal_id).then((response) => response.data)
  }
}

export let sporsmaalService = new SporsmaalService();