import { useContext, useEffect, useState } from "react";
import { Svar, svarService } from "../Services/svar-service";
import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import SletteModal from "./sletteModal";
// import { AuthContext } from "../context/AuthContext";


export function QuestionAlternatives() {
  const [svarSomSkalSlettes, setSvarSomSkalSlettes] = useState<Svar | null>(null);
  const [questionId, setQuestionId] = useState<number>(0)
  const [routeId, setRouteId] = useState<number>(0)
  const [postId, setPostId] = useState<number>(0)
  const [answers, setAnswers] = useState<Svar[] | null>();
  const [alternativeText, setAlternativeText] = useState<string | null>();
  const [altBool, setAltBool] = useState<boolean>(false)
  const handleSlettKlikkSvar = (svar: Svar) => {
    setSvarSomSkalSlettes(svar)
  }
  // const user = useContext(AuthContext)
  const user = false


  const handleBekreftSlettSvar = (answer: Svar | null) => {
    if (answer != null) {
      //sletter svaralternativ
      svarService.delete(answer.svar_id).catch((error) => alert(error.message))
    }
    setSvarSomSkalSlettes(null)
    location.reload()
  }

  //setter svar som skal slettes til null
  const handleIkkeSlettSvar = () => {
    setSvarSomSkalSlettes(null)
  }


  useEffect(() => {

//Bruker url til å sette spørsmålsid, postid og ruteid
    const url = window.location.href.split('/').slice(1);
    const slug = url[url.length - 1]
    const slug2 = url[url.length - 2]
    const slug3 = url[url.length - 3]
    setQuestionId(parseInt(slug))
    setPostId(parseInt(slug2))
    setRouteId(parseInt(slug3))
    if (questionId !== 0 && routeId !== 0 && postId !== 0) {
      findAnswers()
    }


  }, [questionId])

  const findAnswers = () => {
//henter svar tilhørende gitt spørsmål
    svarService.getSporsmaalSvar(routeId, postId, questionId).then(function (response: Svar[]) {
      return setAnswers(response)
    })



  }
  const addAlternative = () => {
    if (alternativeText) {
      //lager nytt svaralternativ til gitt spørsmål
      svarService.create(alternativeText, altBool, questionId).then(function (response) {
        return response;
      })
      location.reload()
    }
    else {
      alert("Du må skrive inn en tekst")
    }
  }



  if (user) return (
/* Card som viser frem svaralternativene */
    <><Card style={{ backgroundColor: 'rgb(230, 240, 241)', display: "flex", border: "none", alignItems: "center" }}> <Card.Body style={{ padding: "10px" }}>
      {answers && answers.map((answer: Svar, index) => (
        <Card key={answer.svar_id} style={{ backgroundColor: 'rgb(230, 240, 241)', maxWidth: " 500px", width: "80vw", justifyContent: 'center', alignItems: "center", border: 'none', background: " rgb(0, 105, 105)", margin: "10px", padding: "10px" }}>
          <h4>Alternativ {index + 1}:</h4>
          <ReactMarkdown>{answer.svar_tekst}</ReactMarkdown>
          {answer.resultat && <p>Riktig svar!</p>}
          <>
            {/* Knapp for å slette gitt svaralternativ */}
            <Button className='slettKnapp' style={{ margin: '2vh', height: '40px', backgroundColor: "#F25749" }} onClick={() => handleSlettKlikkSvar(answer)}>
              Slett svaralternativ
            </Button>
            {/* Modal som vises når slett svaralternaitiv knappen klikkes på */}
            <SletteModal
              visModal={svarSomSkalSlettes !== null}
              elementSomSkalSlettes={"svaralternativ"}
              onCancel={handleIkkeSlettSvar}
              onConfirm={() => handleBekreftSlettSvar(svarSomSkalSlettes)} />
          </>
        </Card>))}
      {!answers || answers.length < 1 && <p>Det finnes ingen alternativer for dette spørsmålet</p>}
    </Card.Body>
    </Card><Card style={{ display: "flex", border: "none", alignItems: "center" }}> <Card.Body style={{ padding: "10px" }}>
      <Card style={{ backgroundColor: 'rgb(230, 240, 241)', maxWidth: " 500px", width: "80vw", justifyContent: 'center', alignItems: "center", border: 'none', background: " rgb(0, 105, 105)", margin: "10px", padding: "10px" }}>
        <h3>Legg til alternativ</h3>
{/* Form for å legge til nye svaralternativer */}
        <Form.Control
          type="text"
          as="textarea"
          rows={1}
          onChange={(e) => setAlternativeText(e.currentTarget.value)}
          placeholder="Alternativ på spørsmål"
          style={{ margin: "10px", textAlign: "center" }} />
        Huk av dersom alternativet er riktig
        <Form.Check
          type="checkbox"
          onClick={() => setAltBool(true)}
          style={{ margin: "10px" }}
        />

          <Button className="lightButton" onClick={() => { addAlternative() }}>Legg til</Button>
      </Card>
      </Card.Body></Card></>

  )
  else return (
    <Card style={{ display: "flex", border: "none", alignItems: "center" }}>
      Du må være logget inn for å se denne siden
    </Card>
  )
}

