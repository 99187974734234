import React from "react";
import { Card } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';

function FAQ() {

// Komponent for frequently asked questions med spørsmål og svar
  return (
    <>
      <Card style={{ border: 'none' }}>
        <Card.Body>
          <Card.Title style={{ fontSize: '50px', textAlign: 'center', border: 'none' }}>
            FAQ
          </Card.Title>
          <Accordion >
            <Accordion.Item eventKey="0" >
              <Accordion.Header >Hva er Gjærevollsenteret?</Accordion.Header>
              <Accordion.Body style={{ color: "white" }}>
                Gjærevollsenteret er et forskningssenter ved NTNU som jobber med naturmangfold og bærekraft. Senteret er bygget på tre hovedpilarer: forskning, utdanning og formidling. Vi er et senter for framtidsanalyser av naturmangfold. Det vil si at vi jobber for å utvikle analyser og verktøy som kan hjelpe oss å forstå hvordan vi påvirker naturen. Dermed blir vi i stand til å ta bedre valg både for samfunnet og naturmangfoldet. Å øke kunnskapen til folk om naturmangfold, hvordan naturen henger sammen og hvordan vi best tar vare på den er et viktig mål for oss. Vi håper at utforskerløypa kan bidra til dette.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" >
              <Accordion.Header  >Hvordan fungerer quizdelen av appen?</Accordion.Header>
              <Accordion.Body style={{ color: "white" }}>
                For å få tilgang til den digitale utforskerløypa kan du skanne QR-kodene som du finner langs løypa og følge instruksjonene.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Hva skjer hvis jeg svarer feil på et spørsmål i quizen?</Accordion.Header>
              <Accordion.Body style={{ color: "white" }}>
                Hvis du svarer feil på et spørsmål i quizen, vil få en forklaring på hvorfor det er feil. Du kan imidlertid fortsette quizen og svare på de resterende spørsmålene. Du vil ikke få poeng for svarene, men du får ny kunnskap.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Kan jeg ta quizen flere ganger?</Accordion.Header>
              <Accordion.Body style={{ color: "white" }}>
                Du kan ta quizen så mange ganger du vil. Postene du har gjennomført vil være markert med grønt på kartet, men du kan gjennomføre dem flere ganger.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Hvor kan jeg finne mer informasjon om biologisk mangfold?</Accordion.Header>
              <Accordion.Body style={{ color: "white" }}>
                Her er noen nettsteder som kan være nyttige: <br></br>
                <a style={{ color: "white" }} href='https://artsdatabanken.no/'>Artsdatabanken</a> <br></br>
                <a style={{ color: "white" }} href='https://snl.no/'>Store norske leksikon</a> <br></br>
                <a style={{ color: "white" }} href='https://miljodirektoratet.no/'>Miljødirektoratet</a> <br></br>
                <a style={{ color: "white" }} href='https://nina.no/'>Norsk institutt for naturforskning</a> <br></br>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Hvem kan jeg kontakte hvis jeg har problemer med å bruke appen?</Accordion.Header>
              <Accordion.Body style={{ color: "white" }}>
                Du kan kontakte Gjærevollsenteret ved NTNU. De vil kunne hjelpe deg med eventuelle spørsmål eller problemer. Er det feil med skiltene langs løypa kan du kontakte Trondheim kommune.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>



    </>
  );
}


export default FAQ;